const i1 =
<svg width="21.14" height="21.14" viewBox="-2 -2 21.14 21.14" x="210">
<path d="M8.57 0a8.57 8.57 0 1 0 8.57 8.57A8.57 8.57 0 0 0 8.57 0zm7.57 8.57a7.54 7.54 0 0 1-.21 1.72L14.35 8.7l1.63-1.63a7.57 7.57 0 0 1 .15 1.48zm-14.77 2.3l1.82-1.8 1.33 1.33v1.9H2a7.52 7.52 0 0 1-.62-1.41zM1 8.56a7.57 7.57 0 0 1 .19-1.52L2.83 8.7l-1.64 1.6A7.54 7.54 0 0 1 1 8.56zm14.84-2L14 8.4l-1.34-1.34V5.14h2.64a7.5 7.5 0 0 1 .54 1.37zM5 7.28l2.1-2.1h3l2.1 2.04v3l-2.1 2.1h-3L5 10.23zm-.5 2.46l-1-1 1-1zm8.15-2l1 1-1 1zm-.47-1.22l-1.4-1.4h1.4zm-1.9-1.9L8.94 3.3l1.94-1.94a7.53 7.53 0 0 1 1.31.55v2.73zm-.7 0h-2l1-1zm-2.67 0H5V1.9a7.52 7.52 0 0 1 1.31-.55L8.2 3.3zm-.5.5l-1.4 1.4V5.14zm-1.9 1.9L3.2 8.36 1.3 6.48a7.51 7.51 0 0 1 .53-1.34h2.7zm.5 3.88l1.4 1.4H5zm1.87 1.9l1.3 1.3-1.67 1.7a7.52 7.52 0 0 1-1.51-.6v-2.4zm.7 0h2l-1 1zm2.67 0h1.9v2.43a7.52 7.52 0 0 1-1.51.61l-1.7-1.7zm.5-.5l1.4-1.4v1.4zm1.9-1.9L14 9.08l1.77 1.73a7.52 7.52 0 0 1-.59 1.48h-2.52zm2.36-5.76h-2.36V2.2a7.61 7.61 0 0 1 2.35 2.43zm-4.7-3.45L8.6 2.95 6.84 1.2a7.31 7.31 0 0 1 3.49 0zm-5.8 1v2.45H2.1a7.61 7.61 0 0 1 2.4-2.48zM2.3 12.8H4.5v2.15a7.62 7.62 0 0 1-2.22-2.15zm4.8 3.2l1.5-1.5 1.5 1.5a7.56 7.56 0 0 1-3 0zm5.58-1.07V12.8h2.18a7.62 7.62 0 0 1-2.17 2.1z" class="G"></path>
</svg> ;

const i2 = 
<svg width="21" height="21" viewBox="-2 -2 21 21" x="210" y="63.28">
    <path d="M0 0v17h17V0zm3.2 14.07v-.94L4.62 11l.58.93a.25.25 0 0 0 .09.08l1.08.6-2.23 1.45zm.2.5l-2.1 1.38 1.4-2.1v.7zM2.7 3.8L1.1 1.36 3.43 2.9H2.7zm.5-.4h1l2.1 1.4-.95.6v.05c0 .05 0 0 0 0l-.65 1.17-1.4-2.1zm8.1 2.44l.72 1.17v3.3l-.77 1.38-1.17.72h-3l-1.46-.82-.72-1.17V7.2l.8-1.4 1.17-.72h3.1zm-1.23-1.28H6.82L5.08 3.4h6.74zM4.36 7.2v3.26L3.2 12.2V5.46zm2.45 5.7h3.26l1.74 1.16H5.07zm3.8-.24l1-.6a.24.24 0 0 0 .08-.08l.65-1.15 1.56 2.36v.87h-1.2zm1.9-2.4V7.02l1.34-2v7.3zm-.24-3.8l-.6-1h-.05c-.05 0 0 0 0 0l-1.05-.6 2.17-1.44h1.14v.68zm1.2-3.57l2.08-1.38-1.2 1.8V2.9zm-.9 0H4.33L1.45 1h14zM1 2.13L2.7 4.7v8.26L1 15.54zm3.3 12.44h8.25l2.2 1.47H2.14zm9.16 0h.9v-.62l1.38 2.1h-.07zm.9-1.52v-8.8l1.66-2.5v13.83z" class="G"></path>
</svg> ;

const i3 = 
<svg width="22.51" height="22.49" viewBox="-2 -2 22.51 22.49" x="128" y="164">
    <path d="M17.77 4.133a5.88 5.88 0 0 0-4.4-3.75 19.55 19.55 0 0 0-4.12-.38 18.39 18.39 0 0 0-4.32.43 5.89 5.89 0 0 0-4.3 3.91 15.14 15.14 0 0 0-.62 4.9 17.25 17.25 0 0 0 .49 4.52 5.63 5.63 0 0 0 4.58 4.33 19.27 19.27 0 0 0 4.17.39 18.92 18.92 0 0 0 4.18-.39 5.64 5.64 0 0 0 4.45-4 15.32 15.32 0 0 0 .61-4.87 13 13 0 0 0-.72-5.09zm-.84 9.7v.08l-1.05-1.68 1.6-2a12.15 12.15 0 0 1-.52 3.53zm-7.68 3.67l-3.94-2.93 4-1.1 4 1.1-3.94 2.93zm-7.8-4a14.23 14.23 0 0 1-.43-3.27l1.6 2-1 1.7c-.07-.14-.12-.3-.15-.4zm.17-9l1 1.57-1.6 2.1a12 12 0 0 1 .53-3.49c.02-.06.05-.06.07-.16zm1.25 7.27l-1.86-2.34v-.4l1.86-2.48 1.6 2.64zm6.38-10.75h.22l3.75 2.8-4 1.1-4-1.1 3.75-2.8zm-4.27 12l2.95.33-3 .82zm8.57 1.14l-3-.82 2.95-.33zm-4.3-1.18L5 12.503l.07-3.25v-.13L5 5.873l4.26-.47 4.26.47-.07 3.37.07 3.25zm4.27-7.58l-2.95-.33 3-.82zm-8.57-1.16l3 .82-2.95.33zm11.92.33a10.18 10.18 0 0 1 .6 3.61l-1.6-2.08 1-1.55zm-1.23 2l1.85 2.5v.4l-1.9 2.34-1.57-2.58zm1-2.57l-2.68 4.33.14-6.7a5 5 0 0 1 2.51 2.36zm-3-2.5v2l-3.23-2.4a16.13 16.13 0 0 1 2.83.33c.07-.03.2.02.36.06zm-8.47-.08a15.15 15.15 0 0 1 3-.37l-3.23 2.4v-1.95zm-.77.2l.13 6.66-2.66-4.33a4.92 4.92 0 0 1 2.53-2.33zm-2.6 12.88l2.72-4.46-.14 6.84a4.65 4.65 0 0 1-2.58-2.38zm3.06 2.53v-2.1l3.42 2.54a16.48 16.48 0 0 1-3.09-.35c-.06-.02-.18-.06-.33-.1zm8.33.1a16 16 0 0 1-3.08.35l3.42-2.54v2.1zm.87-.25l-.14-6.83 2.7 4.45a4.65 4.65 0 0 1-2.54 2.38z" class="G"></path>
</svg> ;

const i4 = <svg width="18" height="24.82" viewBox="-2 -2 18 24.82" x="180" y="84">
<path d="M7 0C6.93 0 .08.12 0 10.42c.2 10.28 6.93 10.4 7 10.4s6.8-.12 7-10.4C13.92.12 7.07 0 7 0zm6.1 9l-3.3-1.92V7l.64-4.7c1.2 1.1 2.4 3.1 2.67 6.7zM.85 10.4V9.5L3.9 7.72a7.73 7.73 0 0 0-.42 2.69 7.66 7.66 0 0 0 .53 2.85L.9 11.4a14.62 14.62 0 0 1-.04-1zM7 15.2s-3-.06-3.08-4.8a6.71 6.71 0 0 1 .62-3l.1-.2a3 3 0 0 1 2.11-1.49h.3a3 3 0 0 1 2.11 1.46v.06l.08.15v.05h.08a6.74 6.74 0 0 1 .61 3C10 15.13 7.12 15.2 7 15.2zm.52-9.93l2.4-2.67-.53 3.88a3.45 3.45 0 0 0-1.88-1.21zm-2.9 1.25L4.1 2.6l2.4 2.67a3.47 3.47 0 0 0-1.87 1.25zm0 7.77a3.44 3.44 0 0 0 1.95 1.27l-2.5 2.78zm4.76 0l.54 4-2.5-2.73a3.42 3.42 0 0 0 1.94-1.23zm1.13-3.88a7.75 7.75 0 0 0-.43-2.66l3 1.76v1.9l-3.12 1.8a7.69 7.69 0 0 0 .55-2.79zm-.58-8.47L7 5.2h-.06l-2.9-3.23A5.39 5.39 0 0 1 7 .85a5.38 5.38 0 0 1 2.93 1.09zm-6.3.4l.64 4.73L.9 8.98c.27-3.52 1.44-5.52 2.7-6.65zm-2.7 9.64l3.3 1.9-.6 4.58c-1.22-1.12-2.36-3.05-2.7-6.5zm3.2 6.92l2.85-3.18 2.87 3.2a5.26 5.26 0 0 1-2.85 1.06 5.28 5.28 0 0 1-2.86-1.07zm6.23-.4l-.62-4.6 3.33-1.93c-.32 3.46-1.44 5.46-2.7 6.54z" class="G"></path>
</svg> ;

const i5 =<svg width="21.22" height="21.01" viewBox="-2 -2 21.22 21.01" x="150.51" y="164">
<path d="M12.67.01a5.47 5.47 0 0 0-4.06 1.46A5.47 5.47 0 0 0 4.551.01C.8.01-5.14 8.84 8.61 17c13.75-8.17 7.8-17 4.06-17zm3.16 3.83L12.99 6.1v-5.1a4.46 4.46 0 0 1 2.84 2.82zm-3.1 2.8zm-2.15-2.3a3.23 3.23 0 0 0-1.1.19l3.06-3.1v4.42a2.39 2.39 0 0 0-1.96-1.47zm-2 7.66c-3.93-2.42-3.82-4.66-3.6-5.52a2.09 2.09 0 0 1 1.64-1.62 2.21 2.21 0 0 1 1.6.54l.37.42.38-.4a2.2 2.2 0 0 1 1.6-.54 2.09 2.09 0 0 1 1.65 1.66c.2.86.3 3.1-3.62 5.52zm-2-7.66a2.36 2.36 0 0 0-1.81 1.52V1.5l3.08 3.1a3.22 3.22 0 0 0-1.22-.25zm-2.2 2.8a4.53 4.53 0 0 0 1.13 2.92l-3.28-.32zm3.85 5.2l-1.3 1.8-.4-3a12.86 12.86 0 0 0 1.69 1.21zm2.6-1.3l-.4 3.1-1.3-1.82a12.58 12.58 0 0 0 1.67-1.27zm2-3.9l2.23 2.6-3.37.38a4.51 4.51 0 0 0 1.09-2.98zm-.6-6.08l-3.4 3.44v-1.8l.5-.54a4.46 4.46 0 0 1 2.9-1.1zM8.33 2.7v1.73L5.01 1.04a4.45 4.45 0 0 1 2.84 1.09zm-4.1-1.64v5.1l-2.84-2.3a4.4 4.4 0 0 1 2.84-2.79zm-3 3.35l3 2.36-2.32 2.6a6.81 6.81 0 0 1-.68-4.95zm1.2 5.84l3.5.4.48 3.66a16.41 16.41 0 0 1-3.99-4.06zm4.62 4.53l1.55-2.16 1.64 2.13c-.5.35-1 .7-1.6 1.06-.6-.35-1.1-.7-1.58-1.03zm3.82-.5l.47-3.62 3.45-.4a16.34 16.34 0 0 1-3.92 4.01zm4.46-4.94l-2.23-2.6L16 4.4a6.8 6.8 0 0 1-.67 4.92z" class="G"></path>
</svg>;

const i6 = <svg width="16" height="24.77" viewBox="-2 -2 16 24.77" x="124" y="80">
<path d="M6.07 0H6c-.06 0-6 .56-6 8 0 7.26 5.5 12.3 5.73 12.52l.27.25.27-.25C6.5 20.3 12 15.26 12 8c.04-7.43-5.87-8-5.93-8zm0 14.7a9.13 9.13 0 0 1-2.67-6c0-3.34 2.43-3.68 2.67-3.7s2.67.37 2.67 3.7a9.15 9.15 0 0 1-2.7 6.01zm3.08-6v-1l2 1-2.13 1.24a7.48 7.48 0 0 0 .1-1.23zm-2.66-4l2.47-2.4L8.8 6.8a3.44 3.44 0 0 0-2.34-2.12zm-3.2 2.2l-.16-4.54 2.46 2.37a3.44 3.44 0 0 0-2.33 2.14zm-.34 1.9a7.52 7.52 0 0 0 .12 1.16L.92 8.8l2-1zm.13 1.66l.4 5.92a16.37 16.37 0 0 1-2.51-7zm.85 6.62l-.36-5.4a10.37 10.37 0 0 0 2.21 3.37l-1.8 2.08zm4.6-5.25l-.36 5.3-1.8-2.08a10.36 10.36 0 0 0 2.14-3.26zm.5-1.4l2.17-1.15a16.39 16.39 0 0 1-2.58 7.2zm2.23-2.36v.3l-2-1 .17-4.8a7.54 7.54 0 0 1 1.81 5.47zM6.04.83a5.71 5.71 0 0 1 2.62 1.11L6 4.55l-2.67-2.6A5.7 5.7 0 0 1 6.04.83zM2.66 2.55l.17 4.8-2 1V8a7.58 7.58 0 0 1 1.83-5.46zM6 19.66a19.53 19.53 0 0 1-1.86-2.23L6 15.3l1.86 2.14a19.52 19.52 0 0 1-1.82 2.22z" class="G"></path>
</svg> ;

const i7 = <svg width="19" height="24.57" viewBox="-2 -2 19 24.57" x="124" y="106">
<path d="M12 0H3L0 3.08V18l2.32 2.57h10.36L15 18V3.1zm-1.93 3.5H4.95L3.66.88h7.67zm-5.13.44h5.12l1.6 1.77v9.52l-1.18 1.42H4.53l-1.18-1.42V5.7zm-.4 13.15h5.9l1.6 2.6h-9zm6.3-.2l1-1.26 2.23 2-1.62 1.9zm1.3-1.67V5.7l2-1.88v13.25zm1.9-11.9l-2.12 2-1.45-1.6 1.3-2.7zm-10.8-2.3L4.56 3.7 3.12 5.3.93 3.4zm-.32 4.7v9.6l-2 2V3.93zM1 17.8l2.1-2.13 1 1.18-1.56 2.7z" class="G"></path>
</svg> ;

export default [i1,i2,i3,i4,i5,i6,i7]