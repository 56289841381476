import React from 'react'
import Header from '../../home/Header/Header'

export default function MobileCart() {
  return (
    <div>
        <Header name='Cart'/>
    </div>
  )
}
