import React from 'react'
import PersnolCheckOutForm from './persnolCheckForm/PersnolCheckOutForm'

export default function CheckOutSummry() {
  return (
    <div>
      <PersnolCheckOutForm />
    </div>
  )
}
